import React from "react";
import {
    makeStyles, Theme, Drawer, useMediaQuery, Typography,
} from "@material-ui/core";
import { Switch, Route, useLocation } from "react-router-dom";
import Footer from "./Footer";
import ProjectsPage from "../pages/ProjectsPage";
import ProjectPage from "../pages/ProjectPage";
import AppBar from "./AppBar";
import AboutPanel from "./AboutPanel";
import { BottomMenu, SideMenu } from "./Menu";
import { importantize } from "../Utils";
import AboutPage from "../pages/AboutPage";
import EducationPage from "../pages/EducationPage";
import SkillsPage from "../pages/SkillsPage";
import MissingPage from "../pages/MissingPage";

export const DRAWER_WIDTH = 300;

const useStyles = makeStyles(theme => ({
    footer: {
        marginTop: "auto",
        marginBottom: theme.spacing(1),
        width: "100%",
    },
    aside: {
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.primary.main,
        position: "fixed",
    },
    asidePlaceholder: {
        minWidth: DRAWER_WIDTH,
    },
    main: {
        maxWidth: "1000px",
        width: "100%",
        margin: "0 auto",
        padding: theme.spacing(4),
    },
    middle: {
        display: "flex",
        flexGrow: 1,
    },
    drawerPaper: {
        position: "relative",
        height: "100%",
        overflowX: "hidden",
        color: "#FFFFFF",
        width: DRAWER_WIDTH,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: "transparent",
        zIndex: "inherit",
        border: "none",
    },
    smallDrawerPaper: {
        backgroundColor: importantize(theme.palette.primary.main),
    },
    drawerRoot: {
        position: "sticky",
        height: "100%",
    },
    title: {
        width: "100%",
        textAlign: "center",
        fontSize: "1.8rem",
        margin: "32px 0",
        flexShrink: 0,
    },
}));

const Layout = () => {

    const classes = useStyles();
    const location = useLocation();
    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
    const [ menu, setMenu ] = React.useState(false);

    React.useEffect(() => {
        setMenu(false);
    }, [ location ]);

    return <>
        { isSmall && <AppBar openMenu={() => setMenu(true)} /> }
        <div className={classes.middle}>
            <aside className={classes.aside}>
                <Drawer
                    variant={isSmall ? "temporary" : "permanent"}
                    open={menu}
                    onClose={() => setMenu(false)}
                    classes={{
                        root: classes.drawerRoot,
                        paper: `${classes.drawerPaper} ${isSmall ? classes.smallDrawerPaper : ""}`,
                    }}
                >
                    <Typography variant="h1" color="inherit" noWrap className={classes.title}>
                        Marek R. J. Polák
                    </Typography>
                    <SideMenu />
                    <AboutPanel />
                    <footer className={classes.footer}>
                        <Footer />
                    </footer>
                </Drawer>
            </aside>
            { !isSmall && <div className={classes.asidePlaceholder} /> }
            <main className={classes.main}>
                <Switch>
                    <Route exact path="/projects/:slug">
                        <ProjectPage />
                    </Route>
                    <Route exact path="/projects">
                        <ProjectsPage />
                    </Route>
                    <Route exact path="/education">
                        <EducationPage />
                    </Route>
                    <Route exact path="/skills">
                        <SkillsPage />
                    </Route>
                    <Route exact path="/">
                        <AboutPage />
                    </Route>
                    <Route path="*">
                        <MissingPage path={location.pathname} />
                    </Route>
                </Switch>
            </main>
        </div>
        { isSmall && <BottomMenu /> }
    </>;
};

export default Layout;

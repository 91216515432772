import React from "react";

export const useMountedRef = () => {

    const isMountedRef = React.useRef<boolean>(false);
    React.useEffect(() => {
        isMountedRef.current = true;
        return () => { isMountedRef.current = false; };
    });
    return isMountedRef;
};

export default useMountedRef;

import React, { FunctionComponent } from "react";
import { Typography, CircularProgress, makeStyles } from "@material-ui/core";
import { useNavigation } from "../contexts/NavigationContext";

const useStyles = makeStyles(theme => ({
    header: {
        fontSize: "3rem",
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            fontSize: "2rem",
        },
    },
    progress: {
        margin: `${theme.spacing(10)}px auto`,
        display: "block",
    },
}));

type Props = {
    loading?: boolean,
    title?: string,
    pretitle?: React.ReactNode,
};

const Page: FunctionComponent<Props> = ({ loading, title, pretitle, children }) => {

    const classes = useStyles();
    const { setTitle } = useNavigation();

    React.useEffect(() => {
        setTitle(title || "");
    }, [ title, setTitle ]);

    return loading ? <CircularProgress size={100} className={classes.progress} /> : <>
        { pretitle }
        { title && <Typography variant="h2" className={classes.header}>{title}</Typography> }
        { children }
    </>;
};

export default Page;

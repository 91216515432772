import React from "react";
import { makeStyles, Avatar, Typography, Grid } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import Config from "../Config";

const useStyles = makeStyles(theme => ({
    about: {
        width: "100%",
        padding: theme.spacing(4),
    },
    avatar: {
        width: theme.spacing(16),
        height: theme.spacing(16),
        margin: "auto",
    },
    header: {
        textAlign: "center",
        fontSize: "24px",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    contactIcon: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        width: theme.spacing(5),
        height: theme.spacing(5),
        borderRadius: 5,
        padding: 10,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}));

const AboutPanel = () => {

    const classes = useStyles();
    const [ mail, setMail ] = React.useState("");

    React.useEffect(() => {
        const obfa = "zc";
        const obfb = "kalopkeram";
        const obfc = "aj";
        const obfd = "otliam";
        setMail(`${obfa}.${obfb}@${obfc}:${obfd}`.split("").reverse().join(""));
    }, [ ]);

    return <div className={classes.about}>
        <Avatar alt="Marek Polák" src="img/avatar.png" className={classes.avatar} />
        <Typography variant="h2" className={classes.header}>Kontakt</Typography>
        <Grid container direction="row" spacing={2} justify="center">
            <Grid item>
                <Avatar
                    alt="E-mail"
                    component="a"
                    className={classes.contactIcon}
                    href={`${mail}?subject=Zpráva z webu`}
                >
                    <EmailIcon />
                </Avatar>
            </Grid>
            <Grid item>
                <Avatar
                    alt="LinkedIn"
                    component="a"
                    className={classes.contactIcon}
                    src="img/linkedin.png"
                    href={Config.links.linkedin}
                    target="_blank"
                />
            </Grid>
        </Grid>
    </div>;
};

export default AboutPanel;

import React from "react";
import { ListItem, ListItemText, ListItemIcon, makeStyles } from "@material-ui/core";
import { Link as LinkIcon } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    hover: {
        cursor: "pointer",
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
        color: "inherit",
    },
    primary: {
        color: theme.palette.primary.main,
        // fontWeight: 600,
    },
}));

type Props = {
    href: string,
    text: string,
};

const LinkListItem = ({ href, text }: Props) => {

    const classes = useStyles();

    return <ListItem className={classes.hover} component="a" href={href} target="_blank">
        <ListItemIcon><LinkIcon color="primary" /></ListItemIcon>
        <ListItemText primary={text} classes={{ primary: classes.primary }} />
    </ListItem>;
};

export default LinkListItem;

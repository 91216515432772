import React from "react";
import {
    Person as AboutIcon,
    Casino as ProjectsIcon,
    School as EducationIcon,
    Settings as SkillIcon,
} from "@material-ui/icons";

export type Path = {
    title: string,
    path: string,
    icon: React.ReactElement,
};

export default [ {
    title: "O mně",
    path: "/",
    icon: <AboutIcon />,
}, {
    title: "Projekty",
    path: "/projects",
    icon: <ProjectsIcon />,
}, {
    title: "Vzdělání",
    path: "/education",
    icon: <EducationIcon />,
}, {
    title: "Dovednosti",
    path: "/skills",
    icon: <SkillIcon />,
} ] as Path[];

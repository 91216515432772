import React from "react";
import {
    List, ListItem, ListItemAvatar, Avatar, ListItemText, Collapse, makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import Page from "./Page";
import { Skill, GetSkills, Skills } from "../providers/DataProvider";
import { useMountedRef } from "../hooks/useMountedRef";

type SkillProps = {
    skill: Skill,
    levels: string[],
    depth?: number,
};

const getSkillLevel = (level?: number, levels?: string[]): string | undefined => {
    return level !== undefined
        ? (levels || [])[level]
        : undefined;
};

const sortSkills = (skills: Skill[]) => skills.sort((a, b) => (b.level || -1) - (a.level || -1));

const SkillItem: React.FC<SkillProps> = ({ skill, levels, depth = 0 }) => {

    const [ open, setOpen ] = React.useState(false);

    return <>
        <ListItem button onClick={() => setOpen(!open)} style={{ paddingLeft: depth * 24 }}>
            <ListItemAvatar>
                <Avatar src={skill.icon || "img/dummy2.jpg"} variant="rounded" />
            </ListItemAvatar>
            <ListItemText primary={skill.name} secondary={getSkillLevel(skill.level, levels)} />
            { skill.sub?.length && (open ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        { skill.sub?.length && <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                { sortSkills(skill.sub).map(sk =>
                    <SkillItem key={sk.name} skill={sk} levels={levels} depth={depth + 1} />,
                )}
            </List>
        </Collapse> }
    </>;
};

const useStyles = makeStyles(() => ({
    topList: {
        maxWidth: 400,
    },
}));

const SkillsPage = () => {

    const classes = useStyles();
    const isMounted = useMountedRef();
    const [ loading, setLoading ] = React.useState(false);
    const [ skills, setSkills ] = React.useState<Skills>();

    React.useEffect(() => {
        setLoading(true);
        GetSkills().then(sk => {
            if (!isMounted.current) return;
            setSkills(sk);
        }).finally(() => {
            if (!isMounted.current) return;
            setLoading(false);
        });
    }, [ isMounted ]);

    return <Page title="Dovednosti" loading={loading}>
        <List className={classes.topList}>
            {
                skills && sortSkills(skills.skills)
                    .map(skill =>
                        <SkillItem key={skill.name} skill={skill} levels={skills.levels} />,
                    )
            }
        </List>
    </Page>;
};

export default SkillsPage;

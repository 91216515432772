import React from "react";
import { Grid, Avatar, Typography, makeStyles, Collapse, List } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import Page from "./Page";
import { getFromToString } from "../Utils";
import { Subject, GetSubjects, Education, GetEducation } from "../providers/DataProvider";
import { useMountedRef } from "../hooks/useMountedRef";
import Config from "../Config";
import LinkListItem from "../components/LinkListItem";

const useStyles = makeStyles(theme => ({
    hover: {
        cursor: "pointer",
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
        color: "inherit",
    },
    title: {
        fontSize: "1.2rem",
    },
    avatar: {
        width: theme.spacing(20),
        height: theme.spacing(20),
        margin: `auto ${theme.spacing(2)}px`,
        filter: "grayscale(1)",
    },
}));

type EducationProps = {
    education: Education,
};

const EducationInfo: React.FC<EducationProps> = ({
    education: { title, program, field, specialization, from, to, icon },
    children,
}) => {

    const classes = useStyles();
    const [ open, setOpen ] = React.useState(false);

    return <>
        <Grid
            item
            container
            direction="row"
            alignItems="center"
            component="div"
            className={children ? classes.hover : undefined}
            onClick={() => setOpen(!open)}
        >
            <Grid item>
                <Avatar
                    className={classes.avatar}
                    src={icon}
                />
            </Grid>
            <Grid item>
                <Typography className={classes.title}>{title}</Typography>
                <Typography variant="overline">{getFromToString(to, from)}</Typography>
                { program && <Typography>Program: {program}</Typography> }
                { field && <Typography>Obor: {field}</Typography> }
                { specialization && <Typography>Zaměření: {specialization}</Typography> }
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
                { children && (open ? <ExpandLess /> : <ExpandMore />)}
            </Grid>
        </Grid>
        { children && <Collapse in={open} timeout="auto" unmountOnExit>
            {children}
        </Collapse> }
    </>;
};

const EducationPage = () => {

    const [ loading, setLoading ] = React.useState(false);
    const [ subjects, setSubjects ] = React.useState<Subject[]>([]);
    const [ education, setEducation ] = React.useState<Education[]>([]);
    const isMounted = useMountedRef();

    React.useEffect(() => {
        setLoading(true);
        Promise.all([ GetEducation(), GetSubjects() ])
            .then(([ eds, subjs ]) => {
                if (!isMounted.current) return;
                setEducation(eds);
                setSubjects(subjs);
            })
            .finally(() => {
                if (!isMounted.current) return;
                setLoading(false);
            });
    }, [ isMounted ]);

    const edSub = React.useMemo(() => {
        return education.map(ed => subjects
            .filter(sub => sub.date >= ed.from && sub.date <= ed.to)
            .sort((a, b) => a.code.localeCompare(b.code)));
    }, [ education, subjects ]);

    console.log(edSub);

    return <Page title="Vzdělání" loading={loading}>
        <Grid container direction="column" spacing={4} style={{ width: "100%" }}>
            { education.map((ed, index) =>
                <EducationInfo key={ed.title} education={ed}>
                    { !!edSub[index]?.length && <List dense>
                        { edSub[index].map(({ name, code, year }) =>
                            <LinkListItem
                                key={code}
                                text={`${code} - ${name}`}
                                href={Config.links.subject(code, year)}
                            />,
                        )}
                    </List> }
                </EducationInfo>,
            )}
        </Grid>
    </Page>;
};

export default EducationPage;

import React from "react";
import "./App.css";
import {
    CssBaseline, ThemeProvider,
} from "@material-ui/core";
import { BrowserRouter as Router } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";
import Layout from "./layout/Layout";
import AppTheme from "./Theme";
import { NavigationProvider } from "./contexts/NavigationContext";

const App = () => {

    return <>
        <CssBaseline />
        <ThemeProvider theme={AppTheme}>
            <Router>
                <NavigationProvider>
                    <SimpleReactLightbox>
                        <Layout />
                    </SimpleReactLightbox>
                </NavigationProvider>
            </Router>
        </ThemeProvider>
    </>;
};

export default App;

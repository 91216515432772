import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { useNavigation } from "../contexts/NavigationContext";
import Page from "./Page";
import { About, GetAbout } from "../providers/DataProvider";
import { useMountedRef } from "../hooks/useMountedRef";

const useStyles = makeStyles(() => ({
    avatar: {
        width: 200,
        height: 250,
        float: "right",
        borderRadius: 10,
        marginLeft: 30,
        objectFit: "cover",
        objectPosition: "bottom",
    },
}));

const AboutPage = () => {

    const classes = useStyles();
    const { setTitle } = useNavigation();

    const [ about, setAbout ] = React.useState<About>();
    const [ loading, setLoading ] = React.useState<boolean>(false);
    const isMounted = useMountedRef();

    React.useEffect(() => {
        setLoading(true);
        setTitle("");
        GetAbout().then(abt => {
            if (!isMounted.current) return;
            setAbout(abt);
        }).finally(() => {
            setLoading(false);
        });
    }, [ isMounted, setTitle ]);

    return <Page title="O mně" loading={loading}>
        { about?.img && <img src={about.img} alt="Marek Polák" className={classes.avatar} /> }
        <Typography
            variant="body1"
            component="div"
            style={{ textAlign: "justify" }}
            dangerouslySetInnerHTML={{ __html: about?.text || "" }}
        />
    </Page>;
};

export default AboutPage;

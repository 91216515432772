import React from "react";
import { Typography } from "@material-ui/core";
import Page from "./Page";

type Props = {
    path: string,
};

const MissingPage: React.FC<Props> = ({ path }) => {

    return <Page title="404">
        <Typography
            variant="body1"
            component="div"
            style={{ textAlign: "justify" }}
        >Požadovaná stránka <strong>{path}</strong> bohužel neexistuje.</Typography>
        <Typography style={{ fontSize: "6rem" }}>¯\_(ツ)_/¯</Typography>
    </Page>;
};

export default MissingPage;

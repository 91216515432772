import Config from "../Config";

export type JsonImage = {
    url: string,
    title?: string,
};

export type JsonLink = {
    url: string,
    title?: string,
};

type ProjectBase = {
    slug: string,
    title: string,
    subtitle: string,
    tags: string[],
    date: Date,
};

export type ProjectStub = ProjectBase & {
    image: JsonImage,
};

export type Project = ProjectBase & {
    fromDate?: Date,
    content: string,
    images: JsonImage[],
    links: JsonLink[],
};

export const GetProject = async (slug: string): Promise<Project | undefined> => {
    return fetch(Config.api.project(slug))
        .then(resp => resp.json())
        .catch(err => { console.warn(err); return undefined; })
        .then(project => project && ({
            ...project,
            date: new Date(project.date),
            tags: project.tags || [],
            fromDate: project.fromDate ? new Date(project.fromDate) : undefined,
        }));
};

export const GetProjects = async (): Promise<ProjectStub[]> => {
    return fetch(Config.api.projects)
        .then(resp => resp.json())
        .catch(err => { console.warn(err); return []; })
        .then((projs: ProjectStub[]) => projs.map(proj => ({
            ...proj,
            date: new Date(proj.date),
        })));
};

export type Skills = {
    levels: string[],
    skills: Skill[],
};

export type Skill = {
    name: string,
    icon?: string,
    level?: number,
    sub?: Skill[],
};

export const GetSkills = async (): Promise<Skills> => {
    return Promise.all([
        fetch(Config.api.skills).then(resp => resp.json()),
        fetch(Config.api.config).then(resp => resp.json()),
    ]).then(([ skills, config ]) => ({
        skills,
        levels: config.levels,
    })).catch(err => {
        console.warn(err);
        return { levels: [], skills: [] };
    });
};

export type Subject = {
    code: string,
    name: string,
    year: number,
    date: Date,
};

export const GetSubjects = async (): Promise<Subject[]> => {
    return fetch(Config.api.subjects)
        .then(resp => resp.json())
        .catch(err => { console.warn(err); return []; })
        .then((subj: Subject[]) => subj.map(sub => ({
            ...sub,
            date: new Date(sub.date),
        })));
};

export type About = {
    img: string,
    text: string,
};

export const GetAbout = async (): Promise<About | undefined> => {
    return fetch(Config.api.config)
        .then(resp => resp.json())
        .then(config => config.about)
        .catch(err => { console.warn(err); return undefined; });
};

export type Education = {
    title: string,
    program?: string,
    field?: string,
    specialization?: string,
    icon: string,
    from: Date,
    to: Date,
};

export const GetEducation = async (): Promise<Education[]> => {
    return fetch(Config.api.education)
        .then(resp => resp.json())
        .catch(err => { console.warn(err); return []; })
        .then((education: Education[]) => education.map(ed => ({
            ...ed,
            from: new Date(ed.from),
            to: new Date(ed.to),
        })));
};

export const importantize = (css: string) => `${css} !important`;

export const notUndefined = <T>(x: T | undefined): x is T => x !== undefined;

export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const getFromToString = (date?: Date, fromDate?: Date) => {

    const from = fromDate?.toLocaleDateString(undefined, { year: "numeric", month: "long" });
    const to = date?.toLocaleDateString(undefined, { year: "numeric", month: "long" });

    if (!date) return "";
    if (!fromDate || fromDate.getMonth() === date.getMonth()) return to || "";
    return `${from} - ${to}`;
};

import React from "react";
import { Typography, makeStyles, List } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import { Project, GetProject } from "../providers/DataProvider";
import { useMountedRef } from "../hooks/useMountedRef";
import Page from "./Page";
import { getFromToString } from "../Utils";
import LinkListItem from "../components/LinkListItem";

const useStyles = makeStyles(theme => ({
    thumbnail: {
        height: "200px",
        maxWidth: "100%",
        objectFit: "cover",
        margin: "10px",
        borderRadius: "10px",
        cursor: "pointer",
    },
    subheader: {
        fontSize: "2rem",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.5rem",
        },
        margin: `${theme.spacing(2)}px 0`,
    },
}));

const ProjectPage = () => {

    const classes = useStyles();
    const { slug } = useParams<{ slug: string }>();
    const isMounted = useMountedRef();

    const [ project, setProject ] = React.useState<Project>();
    const [ loading, setLoading ] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!slug) {
            setProject(undefined);
        } else {
            setLoading(true);
            GetProject(slug).then(proj => {
                if (!isMounted.current) return;
                setProject(proj);
                setLoading(false);
            });
        }
    }, [ slug, isMounted ]);

    return <Page
        loading={loading}
        title={project?.title}
        pretitle={
            <Typography variant="overline">
                { getFromToString(project?.date, project?.fromDate) }
            </Typography>
        }
    >
        <Typography variant="overline">{project?.subtitle}</Typography>
        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: project?.content || "" }} />
        { project?.links?.length && <>
            <Typography variant="h3" className={classes.subheader}>Odkazy</Typography>
            <List dense>
                { project.links.map(({ title, url }) =>
                    <LinkListItem key={url} text={title || ""} href={url} />,
                )}
            </List>
        </> }
        { project?.images?.length && <>
            <Typography variant="h3" className={classes.subheader}>Galerie</Typography>
            <SRLWrapper>
                { project.images.map(({ url, title }) =>
                    <img src={url} alt={title} className={classes.thumbnail} key={url} />,
                )}
            </SRLWrapper>
        </> }
    </Page>;
};

export default ProjectPage;

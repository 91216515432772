import React from "react";
import {
    AppBar as MuiAppBar, Toolbar, Typography, IconButton, makeStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles(theme => ({
    title: {
        width: "100%",
        textAlign: "center",
        fontSize: "1.8rem",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.2rem",
        },
    },
    menu: {
        position: "absolute",
    },
}));

type Props = {
    openMenu: undefined | (() => void),
};

const AppBar = ({ openMenu = () => { } }: Props) => {

    const classes = useStyles();

    return <>
        <MuiAppBar position="fixed" elevation={0}>
            <Toolbar disableGutters>
                { openMenu && <IconButton
                    color="inherit"
                    onClick={openMenu}
                    className={classes.menu}
                >
                    <MenuIcon />
                </IconButton> }
                <Typography variant="h1" color="inherit" noWrap className={classes.title}>
                    Marek R. J. Polák
                </Typography>
            </Toolbar>
        </MuiAppBar>
        <Toolbar />
    </>;
};

export default AppBar;

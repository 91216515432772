import React from "react";
import {
    makeStyles, GridList, GridListTile, GridListTileBar,
    useMediaQuery, Theme, Chip,
} from "@material-ui/core";
import { useRouteMatch, Link } from "react-router-dom";
import { ProjectStub, GetProjects } from "../providers/DataProvider";
import { useMountedRef } from "../hooks/useMountedRef";
import Page from "./Page";

const useStyles = makeStyles(theme => ({
    gridList: {
        transform: "translateZ(0)",
    },
    tile: {
        borderRadius: "10px",
    },
    title: {
        color: theme.palette.primary.contrastText,
    },
    titleBar: {
        pointerEvents: "none",
        background: "linear-gradient(to top, rgba(0,0,0,0.7) 0%, "
            + "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    link: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
    tileImg: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        filter: "grayscale(0.8)",
        "&:hover": {
            filter: "grayscale(0)",
        },
    },
    tags: {
        position: "absolute",
        top: 10,
        right: 10,
    },
    chip: {
        marginLeft: theme.spacing(0.5),
    },
}));

const ProjectsPage = () => {

    const classes = useStyles();
    const match = useRouteMatch();
    const isMounted = useMountedRef();
    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

    const [ projects, setProjects ] = React.useState<ProjectStub[]>([]);
    const [ loading, setLoading ] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        GetProjects().then(projs => {
            if (!isMounted.current) return;
            setProjects(projs);
        }).finally(() => {
            if (!isMounted.current) return;
            setLoading(false);
        });
    }, [ isMounted ]);

    return <Page loading={loading} title="Projekty">
        <div style={{ }}>
            <GridList cellHeight={250} cols={isSmall ? 1 : 2} className={classes.gridList}>
                {
                    projects.map(project => <GridListTile
                        cols={1}
                        key={project.slug}
                        component={Link}
                        to={`${match.url}/${project.slug}`}
                        classes={{
                            tile: classes.tile,
                            imgFullHeight: classes.tileImg,
                            imgFullWidth: classes.tileImg,
                        }}
                    >
                        <img src={project.image?.url} alt={`${project.image?.title}`} />
                        <div className={classes.tags}>
                            {
                                project.tags.map(tag =>
                                    <Chip
                                        key={tag}
                                        label={tag}
                                        className={classes.chip}
                                        size="small"
                                        color="secondary"
                                    />,
                                )
                            }
                        </div>
                        <GridListTileBar
                            title={project.title}
                            subtitle={project.subtitle}
                            classes={{
                                root: classes.titleBar,
                                title: classes.title,
                            }}
                        />
                    </GridListTile>)
                }
            </GridList>
        </div>
    </Page>;
};

export default ProjectsPage;

import React from "react";
import { useLocation } from "react-router-dom";
import Config from "../Config";

type NavContext = {
    path: string[],
    title: string,
    setTitle: (title: string) => void,
};

const defaultContext = {
    path: [ "" ],
    title: "",
    setTitle: () => { /* Default implementation */ },
};

const Context = React.createContext<NavContext>(defaultContext);

export const useNavigation = () => React.useContext(Context);

export const NavigationProvider: React.FunctionComponent = ({ children }) => {

    const [ title, setTitle ] = React.useState(Config.title);
    const location = useLocation();

    React.useEffect(() => {
        window.document.title = title
            ? `${title} | ${Config.title}`
            : Config.title;
    }, [ title ]);

    const context = React.useMemo(() => {
        const path = location.pathname.split("/").slice(1);
        return ({ title, path, setTitle });
    }, [ title, location.pathname ]);

    return <Context.Provider value={context}>
        {children}
    </Context.Provider>;
};

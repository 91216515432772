import { createMuiTheme } from "@material-ui/core/styles";
import { TypographyStyleOptions } from "@material-ui/core/styles/createTypography";

const headers: TypographyStyleOptions = {
    fontFamily: "'Pacifico', 'Segoe UI', sans-serif",
};

export default createMuiTheme({
    palette: {
        primary: {
            main: "#0C4767",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#C7F3FF",
        },
        text: {
            primary: "#000000",
            secondary: "#666666",
        },
        background: {
            default: "#BBBBBB",
            paper: "#FFFFFF",
        },
    },
    typography: {
        h1: {
            ...headers,
        },
        h2: {
            ...headers,
        },
        h3: {
            ...headers,
        },
    },
});

import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import {
    makeStyles, List, ListItem, ListItemText, ListItemIcon,
    BottomNavigation, BottomNavigationAction,
} from "@material-ui/core";
import Paths, { Path } from "../Paths";
import { importantize } from "../Utils";
import { useNavigation } from "../contexts/NavigationContext";

const useStyles = makeStyles(theme => ({
    root: {
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    selected: {
        color: theme.palette.primary.main,
        backgroundColor: importantize("#EEEEEE"),
    },
    navtext: {
        fontSize: "1.2rem",
    },
    bottomRoot: {
        width: "100%",
        // position: "sticky",
        bottom: 0,
        backgroundColor: theme.palette.primary.main,
    },
    bottomActionRoot: {
        color: theme.palette.primary.contrastText,
    },
    bottomActionSelected: {
        color: importantize(theme.palette.secondary.main),
    },
}));

export const BottomMenu = () => {

    const classes = useStyles();
    const history = useHistory();
    const { path } = useNavigation();
    const [ page, setPage ] = React.useState(`/${path[0]}`);

    React.useEffect(() => {
        setPage(`/${path[0]}`);
    }, [ path ]);

    React.useEffect(() => {
        history.push(page);
    }, [ page, history ]);

    const getMenuItem = React.useCallback((item: Path) => {
        return <BottomNavigationAction
            label={item.title}
            value={item.path}
            icon={item.icon}
            key={item.path}
            classes={{ root: classes.bottomActionRoot, selected: classes.bottomActionSelected }}
        />;
    }, [ classes ]);

    return <BottomNavigation
        className={classes.bottomRoot}
        value={page}
        showLabels
        onChange={(ev, value) => setPage(value)}
    >
        { Paths.map(getMenuItem) }
    </BottomNavigation>;
};

export const SideMenu = () => {

    const location = useLocation();
    const classes = useStyles();

    const getMenuItem = React.useCallback(({ title, path, icon }: Path) => {
        const selected = `${location.pathname}/`.startsWith(`${path}/`);
        return <ListItem
            button
            key={path}
            component={Link}
            to={path}
            selected={selected}
            classes={{ root: classes.root, selected: classes.selected }}
        >
            <ListItemIcon style={{ color: "unset" }}>{icon}</ListItemIcon>
            <ListItemText primary={title} classes={{ primary: classes.navtext }} />
        </ListItem>;
    }, [ location, classes ]);

    return <List color="primary" component="nav">
        { Paths.map(getMenuItem) }
    </List>;
};

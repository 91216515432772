import React from "react";
import { Typography } from "@material-ui/core";

const Footer = () => {

    return <>
        <Typography variant="body2" align="center">
            { `Copyright © Marek Polák ${new Date().getFullYear()}.` }
        </Typography>
    </>;
};

export default Footer;

const API_URL = "api";

const Config = {
    title: "Marek R. J. Polák - Full Stack Developer",
    api: {
        config: `${API_URL}/config`,
        education: `${API_URL}/education`,
        projects: `${API_URL}/projects`,
        project: (stub: string) => `${API_URL}/projects/${stub}`,
        skills: `${API_URL}/skills`,
        subjects: `${API_URL}/subjects`,
    },
    links: {
        linkedin: "https://www.linkedin.com/in/marekrjp/",
        subject: (code: string, year = 2020) =>
            `https://is.cuni.cz/studium/predmety/index.php?do=predmet&kod=${code}&skr=${year}`,
    },
};

export default Config;
